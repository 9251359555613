<script setup>
import { reactive, ref } from 'vue'
import TableMixin from '@/views/employee/Hooks/TableMixin'
let { pageConfig, pageChange, pageSizeChange } = TableMixin(queryApi)
import { useRouter } from '@/utils/webApi'
import { setItem } from '@/utils/storage'
import {
  deletePeriod,
  getPeriodList,
  savePeriodData,
  getVersionDetail
} from '@/api/pctrakerop131'
import { Message } from 'element-ui'
let Router = useRouter()
const IC_ChargeData = reactive({
  queryLoading: false,
  tableData: [],
  total:0
})
const IC_ChargeDialogData = reactive({
  visibleTmpl: false,
  buttonLoading: false,
  formData: {
    period: ''
  }
})
const columnOptions = ref([
  {
    key: 'periodMonth',
    name: 'Period',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  }
])
function queryApi() {
  IC_ChargeData.queryLoading = true
  const data = {
    pageNum: pageConfig.pageNum,
    pageSize: pageConfig.pageSize,
    total: pageConfig.total
  }
  getPeriodList(data).then((res) => {
    IC_ChargeData.queryLoading = false
    if (res && res.code === 200) {
      IC_ChargeData.tableData = res.data.list || []
      IC_ChargeData.total = res.data.total || 0
      // this.pageConfig.total = data.total
      // IC_ChargeData.total = res.data.total;
    }
  })
}
queryApi()
function beforeClose() {
  IC_ChargeDialogData.formData.period = ''
  IC_ChargeDialogData.visibleTmpl = false
}
function createIcCharge() {
  IC_ChargeDialogData.visibleTmpl = true
}
async function toDetail(val) {
  const { code } = await getVersionDetail(val.id);
  console.log(code)
  if(code != 200) return;
  let date = val.periodMonth
  let id = val.id
  setItem('indexYear__yc', date.slice(2, 4))

  Router.push({
    path: '/pcTracker/IC_ChargeDetail',
    query: {
      id
    }
  })
}
function submitIcDialog() {
  IC_ChargeDialogData.buttonLoading = true
  const data = {
    periodMonth: IC_ChargeDialogData.formData.period
  }
  savePeriodData(data).then((res) => {
    IC_ChargeDialogData.buttonLoading = false
    if (res && res.code === 200) {
      IC_ChargeDialogData.visibleTmpl = false
      queryApi()
    }
  })
}
function deleteIcChargeItem(val) {
  const data = {
    id: val.id
  }
  deletePeriod(data).then((res) => {
    if (res && res.code === 200) {
      Message.success('删除成功')
      queryApi()
    }
  })
}
</script>

<template>
  <div class="IC_Charge">
    <div style="text-align: right; margin-bottom: 12px">
      <el-button type="primary" @click="createIcCharge" v-permission role="create">新建</el-button>
    </div>
    <PocTable
      v-loading="IC_ChargeData.queryLoading"
      :data="IC_ChargeData.tableData"
      stripe
      :current-page.sync="pageConfig.pageNum"
      :total="IC_ChargeData.total"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      :max-height="1000000000000"
      ref="IC_ChargeTable"
    >
      <el-table-column
        v-for="item in columnOptions"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button role="details" v-permission type="text" @click="toDetail(scope.row)">详情</el-button>
          <el-popconfirm
            role="delete"
            title="确认删除?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            v-permission
            @confirm="deleteIcChargeItem(scope.row)"
          >
            <el-button slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </PocTable>
    <el-dialog
      :visible.sync="IC_ChargeDialogData.visibleTmpl"
      :before-close="beforeClose"
      width="30%"
      title="新建 IC Charge"
      append-to-body
      custom-class="IC_Charge_dialog"
    >
      <el-form
        :inline="true"
        label-position="left"
        label-width="60px"
        :model="IC_ChargeDialogData.formData"
      >
        <el-form-item label="Period" prop="period">
          <el-date-picker
            v-model="IC_ChargeDialogData.formData.period"
            placeholder="Period"
            type="month"
            value-format="yyyy-MM"
          >
          </el-date-picker> </el-form-item
      ></el-form>
      <div slot="footer" align="right" class="dialog-footer">
        <el-button @click="beforeClose">取消</el-button>
        <el-button
          type="primary"
          @click="submitIcDialog"
          :loading="IC_ChargeDialogData.buttonLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.IC_Charge {
  background: white;
  padding: 20px;
}
</style>
